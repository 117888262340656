import { utm_type } from "../constants/utmType"
import { debounce } from "../utils/debounce"

const campaignInputChange = debounce((value) => {
    window.dataLayer.push({ event: "campaignInputChange", value })
}, 750)

const mediumInputChange = debounce((value) => {
    window.dataLayer.push({ event: "mediumInputChange", value })
}, 750)

const sourceInputChange = debounce((value) => {
    window.dataLayer.push({ event: "sourceInputChange", value })
}, 750)

const analyticsEvents = {
    // Input Change events
    domainNameChange: debounce((value) => {
        window.dataLayer.push({ event: "domainNameChange", value })
    }, 750),
    utmParamChangeValue: (utmType, value) => {
        if (utm_type.UTM_PARAM_CAMPAIGN == utmType) {
            campaignInputChange(value)
        } else if (utm_type.UTM_PARAM_MEDIUM == utmType) {
            mediumInputChange(value)
        } else if (utm_type.UTM_PARAM_SOURCE == utmType) {
            sourceInputChange(value)
        }
    },
    // Option Events
    optionAddEvent: (paramName, value) => {
        window.dataLayer.push({ event: "optionAdd", paramName, value })
    },
    optionSelectEvent: (paramName, value) => {
        window.dataLayer.push({ event: "optionSelect", paramName, value })
    },
    optionRemoveEvent: (paramName, value) => {
        window.dataLayer.push({ event: "optionRemove", paramName, value })
    },
    // Copy url is handled by GTM id
    // Color Toggle
    toggleColorMenu: (value) => {
        window.dataLayer.push({ event: "toggleColorOption", value: (value ? 'Open' : 'Close') })
    },
    selectColorMenu: (value, colorHex) => {
        window.dataLayer.push({ event: "selectColorOption", value, colorHex })
    },
    // Copy Url
    copyUrl: (value) => {
        window.dataLayer.push({ event: "copyUrl", value })
    },
    // Reorder Event
    reorderEvent: (value) => {
        window.dataLayer.push({ event: "reorderEvent", value })
    },
}

export default analyticsEvents;
