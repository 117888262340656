import * as React from "react"
import MainWrapper from "../config/MainWrapper"
import Homepage from "../containers/Homepage/Homepage"

const IndexPage = () => {
    var pageName = "UTM Campagin Builder"
    var pageDescription =
        "UTM Campagin Builder, made on the stop with dynamic content, order placement with drag and drop tools and color coding affiliation of campaign, source, medium found within a UTM campaign url."
    return (
        <main>
            <MainWrapper pageName={pageName} description={pageDescription}>
                <Homepage />
            </MainWrapper>
        </main>
    )
}

export default IndexPage
