import React, { Component } from "react"
import "./Homepage.scss"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { SwatchesPicker } from "react-color"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import { IconButton } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import AddIcon from "@material-ui/icons/Add"
import CloseIcon from "@material-ui/icons/Close"
import analytics from "../../analytics/analyticsHomepage"
import { utm_type } from "../../constants/utmType"
import analyticsLayerEvents from "../../analytics/analyticsLayer"

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    removed.order = endIndex
    result.splice(endIndex, 0, removed)

    return result
}

const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    display: "flex",
    overflow: "auto",
})

const getItemStyle = (isDragging, draggableStyle, backgroundColor, textColor) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // https://github.com/atlassian/react-beautiful-dnd/issues/1015
    flexGrow: 1,
    flexBasis: 0,
    marginRight: "20px",
    position: "relative",
    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",
    backgroundColor: backgroundColor,
    color: textColor,

    // styles we need to apply on draggables
    ...draggableStyle,
})

class Homepage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            colorRes: {
                baseUrl: {
                    color: "#cfd8dc",
                    propertyField: "Base Url",
                    textColor: "white",
                },
                campaign: {
                    // color: "#e65100",
                    color: "#bbdefb",
                    propertyField: "Campaign",
                    textColor: "white",
                },
                medium: {
                    color: "#e64a19",
                    propertyField: "Medium",
                    textColor: "white",
                },
                source: {
                    color: "#e91e63",
                    propertyField: "Source",
                    textColor: "white",
                },
                finalUrl: {
                    color: "#263238",
                    propertyField: "Final Url",
                    textColor: "white",
                },
            },
            baseUrl: "www.yourdomain.com",
            finalUrl: "",
            finalUrlContent: "",
            utmList: [
                {
                    order: 1,
                    title: "Campaign",
                    name: utm_type.UTM_PARAM_CAMPAIGN,
                    placeholder: "august-launch",
                    value: "August-Ad-Launch",
                    optionList: [],
                },
                {
                    order: 2,
                    title: "Medium",
                    name: utm_type.UTM_PARAM_MEDIUM,
                    placeholder: "social",
                    value: "Social",
                    optionList: [{ name: "Social" }, { name: "Organic" }, { name: "Paid" }, { name: "Email" }, { name: "Affiliates" }],
                },
                {
                    order: 3,
                    title: "Source",
                    name: utm_type.UTM_PARAM_SOURCE,
                    placeholder: "facebook",
                    value: "Facebook",
                    optionList: [{ name: "Facebook" }, { name: "Twitter" }, { name: "Instagram" }],
                },
            ],
            colorPickerOpen: false,
            colorPickerMenuOpen: false,
            isCopiedToClipBoardMessage: false,
        }
        this.isTryLoadingCache = false
    }
    componentDidMount() {
        if (!this.isTryLoadingCache) {
            this.loadFromCache()
            this.isTryLoadingCache = true
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (window && window.localStorage) {
            let prevStateLocal = prevState
            prevStateLocal.finalUrlContent = ""
            window.localStorage.setItem("savedState", JSON.stringify(prevStateLocal))
        }
    }
    filterCacheData(savedStateLocal) {
        // filter always off on page load
        savedStateLocal.colorPickerOpen = false
        savedStateLocal.colorPickerMenuOpen = false
        savedStateLocal.isCopiedToClipBoardMessage = false
        return savedStateLocal
    }
    loadFromCache() {
        // load from cache
        let savedStateLocal = null
        this.compileFinalURL()
        if (window && window.localStorage) {
            savedStateLocal = window.localStorage.getItem("savedState")
        }
        if (!savedStateLocal) {
            // if not saved then default to what is currently there
            savedStateLocal = this.state
        } else {
            savedStateLocal = JSON.parse(savedStateLocal)
        }

        // update color text when loading custom colors, need to recalculate
        let colorResLocal = savedStateLocal.colorRes
        let colorResLocalList = Object.entries(colorResLocal)
        for (let i = 0; i < colorResLocalList.length; i++) {
            colorResLocal[colorResLocalList[i][0]].textColor = this.getContrast(colorResLocalList[i][1].color)
        }
        savedStateLocal.colorRes = colorResLocal

        // filter always off on page load
        this.filterCacheData(savedStateLocal)

        this.setState(savedStateLocal, () => {
            this.compileFinalURL()
            for (let i = 0; i < this.state.utmList.length; i++) {
                analyticsLayerEvents.utmParamChangeValue(this.state.utmList[i].name, this.state.utmList[i].value);
            }
        })
        // this.setState({
        //     colorRes: colorResLocal,
        // })
    }
    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const items = reorder(this.state.utmList, result.source.index, result.destination.index)

        let itemListString = ""
        for (let i = 0; i < items.length; i++) {
            items[i].order = i + 1;
            itemListString += items[i].name;
            if (i !== (items.length - 1)) {
                itemListString += ',';
            }
        }

        this.setState(
            {
                utmList: items,
            },
            () => this.compileFinalURL()
        )
        analytics.reorderEvent(itemListString)
    }
    inputChangeBaseUrl(e, updateValue) {
        let reactObject = {}
        reactObject[updateValue] = e.target.value
        this.setState(reactObject, () => {
            this.compileFinalURL()
        })

        analytics.domainNameChange(e.target.value)
    }
    inputUTMChangeValue(e, updateValue) {
        let reactObject = {}
        let utmListLocal = this.state.utmList
        for (let i = 0; i < utmListLocal.length; i++) {
            if (utmListLocal[i].name === updateValue) {
                utmListLocal[i].value = e.target.value
            }
        }
        reactObject = {
            utmList: utmListLocal,
        }
        this.setState(reactObject, () => {
            this.compileFinalURL()
        })
        analytics.utmParamChangeValue(updateValue, e.target.value)
        analyticsLayerEvents.utmParamChangeValue(updateValue, e.target.value);
    }
    compileFinalURL(overrideItemList) {
        // variable setting
        let finalUrlString = ""
        let finalUrlContent = ""
        let isAddQuestionMark = true

        // utmList
        let utmListLocal = overrideItemList ? overrideItemList : this.state.utmList
        utmListLocal.sort((a, b) => {
            if (a.order < b.order) {
                return -1
            }
            if (a.order > b.order) {
                return 1
            }
            return 0
        })

        let utmListContentString = ""
        let foundActiveValueCount = 0
        // get utm list based on order value
        // precursor
        for (let i = 0; i < utmListLocal.length; i++) {
            if (utmListLocal[i].value !== "") {
                if (foundActiveValueCount > 0) {
                    utmListContentString += "&"
                }
                utmListContentString += utmListLocal[i].name + "=" + utmListLocal[i].value
                foundActiveValueCount++
            }
        }

        foundActiveValueCount = 0
        let utmListContent = utmListLocal.map((utmItem) => {
            let utmAndSymbol = ""
            let utmName = ""
            let utmValue = ""
            if (utmItem.value !== "") {
                if (foundActiveValueCount > 0) {
                    utmAndSymbol = <span className="utm-symbol-seperator">&</span>
                }
                utmName = (
                    <span className="utm-name" style={{ borderBottomColor: this.state.colorRes[utmItem.name].color }}>
                        {utmItem.name}=
                    </span>
                )
                utmValue = (
                    <span className="utm-value" style={{ borderBottomColor: this.state.colorRes[utmItem.name].color }}>
                        {utmItem.value}
                    </span>
                )
                foundActiveValueCount++
            }
            return (
                <span>
                    {utmAndSymbol}
                    {utmName}
                    {utmValue}
                </span>
            )
        })

        let questionMarkContent = ""
        // question mark add
        if (isAddQuestionMark && utmListContentString !== "") {
            questionMarkContent = <span className="utm-symbol-seperator">?</span>
            utmListContentString = "?" + utmListContentString
        }

        finalUrlContent = (
            <div>
                <span>{this.state.baseUrl}</span>
                <span>{questionMarkContent}</span>
                {utmListContent}
            </div>
        )

        finalUrlString = this.state.baseUrl + utmListContentString
        // set state
        this.setState({
            finalUrl: finalUrlString,
            finalUrlContent: finalUrlContent,
        })
    }
    copyToClipBoard() {
        var result = this.copyToClipBoardHelper(this.state.finalUrl)
        if (result) {
            this.setState({ isCopiedToClipBoardMessage: true }, () => {
                setTimeout(() => {
                    this.setState({ isCopiedToClipBoardMessage: false })
                }, 3000)
            })
        }
        analytics.copyUrl(this.state.finalUrl)
    }
    copyToClipBoardHelper(text) {
        // Copies a string to the clipboard. Must be called from within an event handler such as click.
        // May return false if it failed, but this is not always
        // possible. Browser support for Chrome 43+, Firefox 42+, Edge and IE 10+.
        // No Safari support, as of (Nov. 2015). Returns false.
        // IE: The clipboard feature may be disabled by an adminstrator. By default a prompt is
        // shown the first time the clipboard is used (per session).
        if (window.clipboardData && window.clipboardData.setData) {
            // IE specific code path to prevent textarea being shown while dialog is visible.
            return window.clipboardData.setData("Text", text)
        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea")
            textarea.textContent = text
            textarea.style.position = "fixed" // Prevent scrolling to bottom of page in MS Edge.
            document.body.appendChild(textarea)
            textarea.select()
            try {
                return document.execCommand("copy") // Security exception may be thrown by some browsers.
            } catch (ex) {
                console.warn("Copy to clipboard failed.", ex)
                return false
            } finally {
                document.body.removeChild(textarea)
            }
        }
    }
    getContrast = function (hexcolor) {
        // If a leading # is provided, remove it
        if (hexcolor.slice(0, 1) === "#") {
            hexcolor = hexcolor.slice(1)
        }

        // Convert to RGB value
        var r = parseInt(hexcolor.substr(0, 2), 16)
        var g = parseInt(hexcolor.substr(2, 2), 16)
        var b = parseInt(hexcolor.substr(4, 2), 16)

        // Get YIQ ratio
        var yiq = (r * 299 + g * 587 + b * 114) / 1000

        // Check contrast
        return yiq >= 128 ? "black" : "white"
    }
    handleChange(color, event, id) {
        let colorResLocal = this.state.colorRes
        colorResLocal[id].color = color.hex
        colorResLocal[id].textColor = this.getContrast(color.hex)
        this.setState(
            {
                colorRes: colorResLocal,
            },
            this.compileFinalURL()
        )
        return true
        // color = {
        //   hex: '#333',
        //   rgb: {
        //     r: 51,
        //     g: 51,
        //     b: 51,
        //     a: 1,
        //   },
        //   hsl: {
        //     h: 0,
        //     s: 0,
        //     l: .20,
        //     a: 1,
        //   },
        // }
    }
    addSupplanterOption = (itemName, value) => {
        if (itemName && value && value != "") {
            let utmListLocal = this.state.utmList
            for (let t = 0; t < utmListLocal.length; t++) {
                if (utmListLocal[t].name === itemName) {
                    utmListLocal[t].value = value
                    utmListLocal[t].optionList.push({ name: value })
                }
            }
            this.setState({
                utmList: utmListLocal,
            })
            analytics.optionAddEvent(itemName, value)
        }
    }
    utmKeyPress = (e, itemName, value) => {
        if (e.keyCode == 13) {
            this.addSupplanterOption(itemName, value)
        }
    }
    removeSupplanterOption = (event, itemName, value) => {
        event.stopPropagation()
        if (itemName && value && value != "") {
            let utmListLocal = this.state.utmList
            for (let t = 0; t < utmListLocal.length; t++) {
                if (utmListLocal[t].name === itemName) {
                    for (let y = 0; y < utmListLocal[t].optionList.length; y++) {
                        if (utmListLocal[t].optionList[y].name == value) {
                            utmListLocal[t].optionList.splice(y, 1)
                        }
                    }
                }
            }
            this.setState({
                utmList: utmListLocal,
            })
            analytics.optionRemoveEvent(itemName, value)
        }
    }
    activateSupplanterOption = (itemName, optionName) => {
        if (itemName && optionName) {
            let utmListLocal = this.state.utmList
            for (let t = 0; t < utmListLocal.length; t++) {
                if (utmListLocal[t].name === itemName) {
                    utmListLocal[t].value = optionName
                }
            }
            this.setState(
                {
                    utmList: utmListLocal,
                },
                () => this.compileFinalURL()
            )
            analytics.optionSelectEvent(itemName, optionName)
            analyticsLayerEvents.utmParamChangeValue(itemName, optionName);
        }
    }
    clearTextOption = (itemName) => {
        if (itemName) {
            let utmListLocal = this.state.utmList
            for (let t = 0; t < utmListLocal.length; t++) {
                if (utmListLocal[t].name === itemName) {
                    utmListLocal[t].value = ""
                }
            }
            this.setState({
                utmList: utmListLocal,
            })
        }
    }
    render() {
        return (
            <div className="homepage-container">
                <h1>UTMBuilder</h1>
                <div className="header-container">
                    <div className="title-logo">
                        <div>
                            <div className="title-logo-main">UTM</div>
                            <div className="title-logo-secondary">
                                <div>Campaign</div>
                                <div>Builder</div>
                            </div>
                        </div>
                    </div>
                    <div className="header-secondary">
                        <div>
                            <h2>For Collecting Performance of Marketing Campaigns</h2>
                        </div>
                    </div>
                </div>
                <div className="breaker-header">
                    <div class="divider div-transparent"></div>
                </div>
                <div className="main-container">
                    <div
                        className="base-url-container"
                        style={{
                            backgroundColor: this.state.colorRes["baseUrl"].color,
                            color: this.state.colorRes["baseUrl"].textColor ? this.state.colorRes["baseUrl"].textColor : "black",
                        }}
                    >
                        <div className="">
                            <div className="base-url-title">
                                <div className="category-titles">Domain URL</div>
                            </div>
                            <div className="working-space">
                                <div className="base-url-input">
                                    <TextField
                                        id="baseURL"
                                        label="Site URL"
                                        placeholder="Ex: http://www.yourdomain.com"
                                        variant="outlined"
                                        // variant="standard"
                                        value={this.state.baseUrl}
                                        onChange={(e) => this.inputChangeBaseUrl(e, "baseUrl")}
                                        color="primary"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="utm-params-container">
                        <div className="utm-params-wrapper">
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable" direction="horizontal">
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                                            {this.state.utmList.map((item, index) => (
                                                <Draggable key={item.name} draggableId={item.name} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style,
                                                                this.state.colorRes[item.name].color,
                                                                this.state.colorRes[item.name].textColor ? this.state.colorRes[item.name].textColor : "black"
                                                            )}
                                                        >
                                                            <div className="svg-container">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    enableBackground="new 0 0 24 24"
                                                                    viewBox="0 0 24 24"
                                                                    fill={this.state.colorRes[item.name].textColor ? this.state.colorRes[item.name].textColor : "black"}
                                                                    width="18px"
                                                                    height="18px"
                                                                >
                                                                    <g>
                                                                        <rect fill="none" height="24" width="24" />
                                                                    </g>
                                                                    <g>
                                                                        <g>
                                                                            <g>
                                                                                <path d="M20,9H4v2h16V9z M4,15h16v-2H4V15z" />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                            <div className="utm-param-wrapper">
                                                                <div className="utm-param-medium">
                                                                    <div className="title category-titles">{item.title}</div>
                                                                    <div className="working-space">
                                                                        <div className="utm-input utm-input-medium">
                                                                            <FormControl variant="outlined">
                                                                                <InputLabel htmlFor={"outlined-adornment-" + item.name}>{item.name}</InputLabel>
                                                                                <OutlinedInput
                                                                                    className=""
                                                                                    autoComplete="off"
                                                                                    id={"outlined-adornment-" + item.name}
                                                                                    onChange={(e) => this.inputUTMChangeValue(e, item.name)}
                                                                                    placeholder={item.placeholder ? "Ex: " + item.placeholder : ""}
                                                                                    variant="outlined"
                                                                                    value={item.value}
                                                                                    onKeyDown={(e) => this.utmKeyPress(e, item.name, item.value)}
                                                                                    endAdornment={
                                                                                        <InputAdornment position="end">
                                                                                            <IconButton
                                                                                                aria-label="add option"
                                                                                                onClick={() => this.addSupplanterOption(item.name, item.value)}
                                                                                                edge="end"
                                                                                            >
                                                                                                <AddIcon></AddIcon>
                                                                                            </IconButton>
                                                                                        </InputAdornment>
                                                                                    }
                                                                                    labelWidth={70}
                                                                                />
                                                                            </FormControl>
                                                                        </div>
                                                                        <div className="option-list-container">
                                                                            <div
                                                                                className={
                                                                                    "option-list-wrapper " +
                                                                                    (item.optionList.length > 0 ? "option-list-wrapper-populated " : "")
                                                                                }
                                                                            >
                                                                                {item.optionList &&
                                                                                    item.optionList.map((optionItem, index) => {
                                                                                        return (
                                                                                            <Button
                                                                                                key={item.name}
                                                                                                index={index}
                                                                                                className={
                                                                                                    "option-list-item " +
                                                                                                    (optionItem.name &&
                                                                                                    item.value &&
                                                                                                    optionItem.name.toUpperCase() == item.value.toUpperCase()
                                                                                                        ? "option-list-active"
                                                                                                        : "")
                                                                                                }
                                                                                                variant="contained"
                                                                                                color="primary"
                                                                                                onClick={() => this.activateSupplanterOption(item.name, optionItem.name)}
                                                                                            >
                                                                                                <div className="option-name">{optionItem.name}</div>
                                                                                                <div
                                                                                                    className="option-remove"
                                                                                                    onClick={(e) =>
                                                                                                        this.removeSupplanterOption(e, item.name, optionItem.name)
                                                                                                    }
                                                                                                >
                                                                                                    <CloseIcon style={{ fontSize: 20 }}></CloseIcon>
                                                                                                </div>
                                                                                            </Button>
                                                                                        )
                                                                                    })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                    <div className="final-url-container">
                        <div
                            className=""
                            style={{
                                backgroundColor: this.state.colorRes["finalUrl"].color,
                                color: this.state.colorRes["finalUrl"].textColor ? this.state.colorRes["finalUrl"].textColor : "black",
                            }}
                        >
                            <div className="final-url-title">
                                <div className="category-titles">Final URL:</div>
                            </div>
                            <div className="final-url-wrapper">
                                <div style={{ display: "none" }} id="finalURL">
                                    this.state.finalUrl
                                </div>
                                <div className={"final-url-input " + (this.state.finalUrl ? "" : " example-url")}>
                                    {this.state.finalUrl ? this.state.finalUrlContent : "Ex: http://www.yourdomain.com?campaign=relaunch&medium=social&source=facebook"}
                                </div>
                                {this.state.finalUrl && this.state.finalUrl.length > 0 && (
                                    <div className="button-wrapper">
                                        <Button id="copy-url" variant="contained" color="primary" className="copy-url-button" onClick={() => this.copyToClipBoard()}>
                                            Copy URL
                                        </Button>
                                        {this.state.isCopiedToClipBoardMessage && <div className="copied-confirmation">Copied to clipboard!</div>}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="color-changer-container">
                    {this.state.colorPickerMenuOpen && (
                        <div className="cc-wrapper">
                            {Object.entries(this.state.colorRes).map((item, index) => {
                                let itemObj = item[1]
                                return (
                                    <div key={index} index={index}>
                                        <div className={this.state.colorPickerOpenId === item[0] ? "color-title-selected" : ""}>{itemObj.propertyField}: </div>
                                        <div data-cc-color="url" className="color-output">
                                            <div>{itemObj.color}</div>
                                            <div
                                                className="color-sample-wrapper"
                                                onClick={() =>
                                                    this.setState(
                                                        this.state.colorPickerOpen === false
                                                            ? { colorPickerOpen: true, colorPickerOpenId: item[0] }
                                                            : item[0] === this.state.colorPickerOpenId
                                                            ? {
                                                                  colorPickerOpen: false,
                                                                  colorPickerOpenId: "",
                                                              }
                                                            : {
                                                                  colorPickerOpen: true,
                                                                  colorPickerOpenId: item[0],
                                                              }
                                                    )
                                                }
                                            >
                                                <div className={this.state.colorPickerOpenId === item[0] ? "color-sample-selected" : "color-sample-not-selected"}>
                                                    <div className="color-sample" style={{ backgroundColor: itemObj.color }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.colorPickerOpenId == item[0] && this.state.colorPickerOpen && (
                                            <div className="color-picker-container svg-wrapper">
                                                <div className="color-picker-wrapper">
                                                    <SwatchesPicker
                                                        color={itemObj.color}
                                                        onChange={(color, event) => {
                                                            analytics.selectColorMenu(this.state.colorPickerOpenId, color.hex)
                                                            this.handleChange(color, event, this.state.colorPickerOpenId)
                                                        }}
                                                    />
                                                    <div className="color-accept-button">
                                                        <Button
                                                            style={{ backgroundColor: itemObj.color }}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => this.setState({ colorPickerOpen: false, colorPickerOpenId: "" })}
                                                        ></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    )}
                    <Button
                        className="control-color-menu"
                        variant="contained"
                        onClick={() => {
                            analytics.toggleColorMenu(!this.state.colorPickerMenuOpen)
                            this.setState({ colorPickerOpen: false, colorPickerOpenId: "", colorPickerMenuOpen: !this.state.colorPickerMenuOpen })
                        }}
                    >
                        {this.state.colorPickerMenuOpen ? "-" : "+"}
                    </Button>
                </div>
            </div>
        )
    }
}
export default Homepage
